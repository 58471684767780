<template>
  <div>
    <a-row type="flex" justify="space-around">
      <a-col class="pb" :xs="20" :sm="10" :md="4">
        <TypePaiementsList
                :types_reglements="types_reglements"
                @type_reglement_change="
            $emit('type_reglement_change', $event)
          "
        />
      </a-col>
      <a-col class="pb" :xs="20" :sm="10" :md="4">
        <a-range-picker @change="$emit('date_range_change', $event)" />
      </a-col>
      <a-col class="pb" :xs="20" :sm="10" :md="4">
        <a-select
          placeholder="Mode paiement"
          style="width:100%"
          @change="handleModePaimentChange"
        >
          <a-select-option value="">Tous</a-select-option>
          <a-select-option
            v-for="mode_reglement in modes_reglement"
            :key="mode_reglement.id"
            >{{ mode_reglement.name }}</a-select-option
          >
        </a-select>
      </a-col>
      <a-col class="pb" :xs="20" :sm="10" :md="4">
        <EtatSecteurActiviteList
          :localEtats="secteurs"
          @etat_secteur_activite_change="
            $emit('etat_secteur_activite_change', $event)
          "
        />
      </a-col>
      <a-col class="pb" :xs="20" :sm="10" :md="4">
        <WilayasList
          :data="wilayas"
          @wilaya_change="$emit('wilaya_change', $event)"
        />
      </a-col>
      <a-col class="pb" :xs="20" :sm="10" :md="3">
        <SearchInput class="text-end" @change="searchPaiement($event)" />
      </a-col>
    </a-row>
    <br />
    <a-row type="flex">
      <a-col :span="24">
        <ListePaiements
          :modesReglement="modes_reglement"
          :dataSource="dataSource"
          :processing="processing"
          :somme="somme"
          :isAdmin="isAdmin"
        ></ListePaiements>
      </a-col>
    </a-row>
  </div>
</template>

<script>
  import ListePaiements from "@/components/common/paiement/ListePaiements.vue";
  import EtatSecteurActiviteList from "@/components/common/EtatSecteurActiviteList";
  import TypePaiementsList from "@/components/common/TypePaiementsList";
  import WilayasList from "@/components/common/WilayasList";
  import SearchInput from "@/components/common/Search";
  import {mapActions} from "vuex";
  import moment from "moment";
  import debounce from "lodash-es/debounce";

  export default {
    name: "ListePaiementWithFilters",
    created() {
      this.$store.cache.dispatch("fetchSecteurs");
    },
    components: {
      ListePaiements,
      EtatSecteurActiviteList,
      TypePaiementsList,
      WilayasList,
      SearchInput,
  },
  props: [
    "dataSource",
    "processing",
    "modes_reglement",
    "types_reglements",
    "excercices",
    "wilayas",
    "secteurs",
    "isAdmin",
    "somme",
  ],
  data() {
    this.searchPaiement = debounce(this.searchPaiement, 800);
    return {};
  },
  methods: {
    moment,
    searchPaiement($event) {
      this.$emit("change", $event);
    },
    handleModePaimentChange(value) {
      this.$emit("paiement_change", value);
    },
    handleDocuementPritingChange(value) {
      this.$emit("handleDocuementPritingChange", value);
    },
    ...mapActions({ getSecteurs: "fetchSecteurs" }),
  },
  destroyed() {},
};
</script>
<style scoped>
.text-end {
  text-align: end;
}
.pb{
  padding-bottom: 2%;
}
</style>
