<template>
  <div>
    <a-spin :spinning="processing">
      <a-row type="flex" justify="center">
        <a-col :span="24">
          <a-table
            bordered
            v-if="dataSource.length"
            :dataSource="dataSource"
            :columns="columns"
            :pagination="false"
            :rowKey="record => record.id"
          >
            <template slot="row_index" slot-scope="text, record, index">
              {{ index + 1 }}
            </template>
                 <template slot="reference" slot-scope="text, record">
            <span v-bind:class="{ black: isNewPaiement(record) }">
              <a-badge v-if="isNewPaiement(record)" status="processing" />
              {{ record.code }}
            </span>
          </template>
            <template slot="exercice_du" slot-scope="text, record, index">
              {{ get(record, "exercice.annee") | year }}
            </template>
            <template slot="user" slot-scope="text, record, index">
                {{ record.user.nom_fr }} {{ record.user.prenom_fr }}
            </template>
            <template slot="montant" slot-scope="text, record, index">
              {{ record.montant | currency }}
            </template>
            <template slot="piece_jointe" slot-scope="text, record, index">
              <!-- _________________________VISUALIZE -->
              <a-tooltip placement="top">
                <template slot="title">
                  <span>Visualiser</span>
                </template>

                <a-icon
                        type="search"
                        @click="previewDocument(record.file_link)"
                        class="action_icons"
                        v-if="record.file_name"
                />
              </a-tooltip>
            </template>
            <template slot="date_paiement" slot-scope="text, record, index">
              <strong>{{ record.dt_encaissement | date }}</strong>
            </template>
            <template slot="mode_reglement" slot-scope="text, record, index">
              {{getModeReglementName(record) }}
            </template>
             <template
            class="table-operation"
            slot="operation"
            slot-scope="text, record"
          >

            <a-tooltip placement="top">
              <template slot="title">
                <span>Cotisation</span>
              </template>
              <a-icon
                type="dollar"
                @click="goToAdherentCotisation(record.user_id)"
                class="action_icons"
                theme="twoTone"
                twoToneColor="#52c41a"
              />
            </a-tooltip>
            </template>
            <template slot="footer" slot-scope="currentPageData">
              <div class="text-al">
                <span class="total">Total</span>
                <span class="total_price">{{ somme | currency }}</span>
              </div>
            </template>
          </a-table>
          <EmptyResult v-else />
        </a-col>
      </a-row>
    </a-spin>
  </div>
</template>
<script>
  import _remove from "lodash-es/remove";
  import EmptyResult from "@/components/common/EmptyResult";

  const columns = [
    {
      title: "N°",
      scopedSlots: {customRender: "row_index"},
    },
    {
      title: "Référence",
      scopedSlots: {customRender: "reference"},
    },
    {
      title: "Adhérent",
    scopedSlots: { customRender: "user" },
  },
  {
    title: "Type",
    dataIndex: "type_reglement.nom_fr",
  },
  {
    title: "Mode",
    dataIndex: "mode_paiement.name",
  },
  {
    title: "Montant",
    scopedSlots: { customRender: "montant" },
  },
  {
    title: "Date paiement",
    scopedSlots: { customRender: "date_paiement" },
  },

  {
    title: "Pièce jointe",
    scopedSlots: { customRender: "piece_jointe" },
  },
         {
          title: "Actions",
          scopedSlots: { customRender: "operation" },
        },
];
export default {
  name: "ListePaiement",
  components: {
    EmptyResult,
  },
  data() {
    let cols = columns

    let actionsColIndex = columns.length -1 
    let userNameColIndex = 2
    if (!this.isAdmin) {
      cols = _remove(columns, function(value, index, array) {
        return index !== userNameColIndex && index !== actionsColIndex;
      });
    }
    return {
      columns: cols,
    };
  },
  props: ["dataSource", "processing", "modesReglement", "isAdmin", "somme"],
  methods: {
    isNewPaiement(paiement){
      return !this.isPaiementValide(paiement.is_valide) ;
    },
    getModeReglementName(record) {
      let reglement = this.modesReglement
        ? this.modesReglement.find(
            (mode_reglement) => mode_reglement.id === record.idReglement
          )
        : "";

      return reglement ? reglement.name : "";
    },
        goToAdherentCotisation(userId) {
      this.$router.push(`adherents/cotisation/edit/${userId}`);
    },
    previewDocument(link) {
      window.open(link);
    },
  },
};
</script>
<style scoped>
.total {
  font-size: 2rem;
  margin-right: 1%;
}
.total_price {
  font-size: 2.1rem;
  color: #e23434;
}
.text-al {
  text-align: right;
}
</style>
