<template>
    <a-select
            :allowClear="true"
            :defaultValue="selectedValue"
            @change="handleTypeReglementChange"
            style="width:100%"
            v-decorator="[
      'type_reglement',
      {
        rules: [
          {
            required: mandatory,
            message: 'Type de paiement  est obligatoire!'
          },
          { validator: checkEmptyValue }
        ]
      }
    ]"
    >
        <a-select-option :key="0">Tous</a-select-option>
        <a-select-option v-for="typeReglement in types_reglements" :key="typeReglement.id">
            {{ typeReglement.nom_fr }}
        </a-select-option>
    </a-select>
</template>
<script>
    export default {
        name: "TypePaiementsList",
        props: {
            types_reglements: Array,
            selectedValue: {type : Number , default : () => 0},
            mandatory: {
                type: Boolean,
                default: () => false
            }
        },
        methods: {
            handleTypeReglementChange(selectedItem) {
                this.$emit("type_reglement_change", selectedItem);
            },
            checkEmptyValue(rule, value, callback) {
                const defaultValue = 0;
                let message = "Il faut choisir une option";
                this.checkDefaultValue(defaultValue, callback, parseInt(value), message);
            }
        }
    };
</script>
