<template>
  <a-select
    :allowClear="true"
    :defaultValue="selectedValue"
    @change="handleEtatSecteurActiviteChange"
    style="width: 100%"
    v-decorator="[
      'secteur_activite',
      {
        rules: [
          {
            required: true,
            message: 'Secteur activite est obligatoire!'
          },
          { validator: checkEmptyValue }
        ]
      }
    ]"
    placeholder="Secteur activite"
  >
    <a-select-option :key="0">Choisir un secteur</a-select-option>
    <a-select-option v-for="etat in localEtats" :key="etat.id">
      {{ etat.nom_fr }}
    </a-select-option>
  </a-select>
</template>
<script>
export default {
  name: "EtatSecteurActiviteList",
  props: {
    localEtats: Array,
    selectedValue: {type : Number , default : () => 0},
    mandatory: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    handleEtatSecteurActiviteChange(selectedItem) {
      this.$emit("etat_secteur_activite_change", selectedItem);
    },
    checkEmptyValue(rule, value, callback) {
      const defaultValue = 0;
      let message = "Il faut choisir une option";
      this.checkDefaultValue(defaultValue, callback, parseInt(value), message);
    }
  }
};
</script>