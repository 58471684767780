<template>
  <a-select
    :defaultValue="selectedValue"
    :allowClear="true"
    @change="handleWilayasChange"
    style="width: 100%"
    v-decorator="[
      'wilaya_id',
      {
        rules: [
          {
            required: true,
            message: 'Wilaya d\'activité est obligatoire!',
          },
          { validator: checkEmptyValue },
        ],
      },
    ]"
    placeholder="Choisir  une wilaya"
  >
    <a-select-option value="">Choisir une wilaya</a-select-option>
    <a-select-option v-for="wilaya in data" :key="wilaya.id">{{
      wilaya.nom_fr
    }}</a-select-option>
  </a-select>
</template>
<script>
export default {
  name: "WilayasList",
  props: {
    data: Array,
    selectedValue: { type: Number },
    mandatory: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    handleWilayasChange(selectedItem) {
      this.$emit("wilaya_change", selectedItem);
    },
    checkEmptyValue(rule, value, callback) {
      const defaultValue = 0;
      let message = "Il faut choisir une option";
      this.checkDefaultValue(defaultValue, callback, parseInt(value), message);
    },
  },
};
</script>
